import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import BookingButton from '../../components/BookingForm/BookingButton';
import CheckoutStepsAndRecipt from '../../components/BookingForm/CheckoutStepsAndRecipt';
import {
    checkoutAddressDistance,
    checkoutUpdateData,
} from '../../store/Actions';

function AddressDistance({
    checkout,
    doCheckoutAddressDistance,
    doCheckoutUpdateData,
}) {

    const memoizedDoCheckoutAddressDistance = useCallback(() => {
        return doCheckoutAddressDistance({
            origins: checkout.origins,
            destination: checkout.destination
        });
    }, [checkout.origins, checkout.destination, doCheckoutAddressDistance]);

    useEffect(() => {
        memoizedDoCheckoutAddressDistance()
    }, [memoizedDoCheckoutAddressDistance]);

    return (
        <div className="card">
            <div>
                <span className="text-4xl">
                    Cost For Delivery
                </span>
            </div>

            <CheckoutStepsAndRecipt/>

           {checkout.isLoading ?
                <div className="pt-10 pb-10">
                    Calculating distance...<br/>
                    <img src="./spinner.png" alt="loading"
                        className="object-contain w-10 m-auto animate-spin"/>
                </div>
            :
                <div className="pt-10 pb-10 text-xl">
                    Distance: { checkout.distance }<br/>
                    Cost: ${ checkout.price }
                </div>
            }

            <div className="grid grid-cols-2 gap 2 align-items-center pb-5 align-items-center">
                <BookingButton step="DeliverySuggestions">
                    Back
                </BookingButton>
                <BookingButton step="ContactInformation">
                    Continue
                </BookingButton>
            </div>

        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doCheckoutAddressDistance: (data) => dispatch(checkoutAddressDistance(data)),
    doCheckoutUpdateData: (index, data) => dispatch(checkoutUpdateData(index, data)),
});

const mapStateToProps = (state) => ({
    checkout: state.checkout,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressDistance);
