import React from 'react';
import { connect } from 'react-redux';
import { changeCheckoutStep } from '../../store/Actions';

import BookingButton from '../../components/BookingForm/BookingButton';
import BookingInput from '../../components/BookingForm/BookingInput';
import CheckoutStepsAndRecipt from '../../components/BookingForm/CheckoutStepsAndRecipt';

function ContactInformation({
    checkout,
    onChangeCheckoutStep,
}) {
    const handleSubmit = (e) => {
        e.preventDefault();
        onChangeCheckoutStep("SchedulePickup");
    }
    return (
        <div className="card">
            <div>
                <span className="text-3xl">
                    Your Contact Information
                </span>
            </div>

            <CheckoutStepsAndRecipt/>
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-2 m-5">
                    <div>
                        First Name:
                        <BookingInput field="firstName" placeHolder="First Name"/>
                    </div>
                    <div>
                        Last Name:
                        <BookingInput field="lastName" placeHolder="Last Name"/>
                    </div>
                    <div className="col-span-2">
                        Email:
                        <BookingInput field="email" placeHolder="Email"/>
                    </div>
                    <div className="col-span-2">
                        Phone Number:
                        <BookingInput field="phone" placeHolder="Phone"/>
                    </div>
                </div>

                <div className="flex grid grid-cols-3 align-items-center pt-5 align-items-center">
                    <div className="col-span-1">
                        <BookingButton step="DeliverySuggestions">
                            Back
                        </BookingButton>
                    </div>
                    <div className="col-span-2">
                        <button className="card-button">
                            Continue
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    onChangeCheckoutStep: step => dispatch(changeCheckoutStep(step)),
});

const mapStateToProps = (state) => ({
    checkout: state.checkout,
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformation);
