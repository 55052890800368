import { createSlice } from '@reduxjs/toolkit';

export const UserSlice = createSlice({
    name: "user",
    initialState: {
        user: null,
        customToken: null,
    },
    reducers: {
        login: (state, action) => {
            state.user = action.payload;
        },
        logout: (state) => {
            state.user = null;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setUserToken: (state, action) => {
            state.customToken = action.payload
        },
        clearUserData: (state, action) => {
            state.user = null;
        },
    },
});

export const { setUser, setUserToken, clearUserData, login, logout } = UserSlice.actions;
export const selectUser = (state) => state.user.user;
export default UserSlice.reducer;
