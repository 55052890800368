import React, { useState } from "react";
import { connect } from 'react-redux';

import {
    authLogin,
} from '../../store/Actions';

const Login = ({
    doAuthLogin,
}) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const handleLogin = () => {
        doAuthLogin({email, password});
    };
    return (
        <div className="flex flex-col max-w-xl m-auto mt-20 border border-md shadow-lg">
            <div className="bg-gray-200/50 p-5">
                <h1>Login</h1>
                    Email:
                    <br />
                <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    />
                <br />
                    Password:
                <br />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    />
                <br />
                <br />

                <button className="card-button " onClick={handleLogin}>Log In</button><br/>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    doAuthLogin: (user) => dispatch(authLogin(user)),
});

const mapStateToProps = (state) => ({
    checkout: state.checkout,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
