export const firstName = (state) => state.checkout.firstName;
export const lastName = (state) => state.checkout.lastName;
export const email = (state) => state.checkout.email;
export const phone = (state) => state.checkout.phone;
export const pickupLocation = (state) => state.checkout.pickupLocation;
export const pickupLocationSuggested = (state) => state.checkout.pickupLocationSuggested;
export const pickupTime = (state) => state.checkout.pickupTime;
export const pickupInstructions = (state) => state.checkout.pickupInstructions;
export const origins = (state) => state.checkout.origins;
export const deliveryLocation = (state) => state.checkout.deliveryLocation;
export const deliveryLocationSuggested = (state) => state.checkout.deliveryLocationSuggested;
export const deliveryTime = (state) => state.checkout.deliveryTime;
export const deliveryInstructions = (state) => state.checkout.deliveryInstructions;
export const destination = (state) => state.checkout.destination;
export const distance = (state) => state.checkout.distance;
export const order = (state) => ({
    firstName: state.checkout.firstName,
    lastName: state.checkout.lastName,
    email: state.checkout.email,
    phone: state.checkout.phone,
    pickupLocation: state.checkout.pickupLocation,
    pickupLocationSuggested: state.checkout.pickupLocationSuggested,
    pickupTime: state.checkout.pickupTime,
    pickupInstructions: state.checkout.pickupInstructions,
    origins: state.checkout.origins,
    deliveryLocation: state.checkout.deliveryLocation,
    deliveryLocationSuggested: state.checkout.deliveryLocationSuggested,
    deliveryTime: state.checkout.deliveryTime,
    deliveryInstructions: state.checkout.deliveryInstructions,
    destination: state.checkout.destination,
    distance: state.checkout.distance,
    clientSecret: state.checkout.clientSecret,
    price: state.checkout.price,
    isDelivered: state.checkout.isDelivered,
})