import React from 'react';
import CheckoutStepsAndRecipt from '../../components/BookingForm/CheckoutStepsAndRecipt';

function Receipt() {

    return(
        <div className="card pb-5">
            <div>
                <span className="text-3xl">
                    We will contact you shortly for your delivery.
                </span>
            </div>
            <CheckoutStepsAndRecipt/>
        </div>
    );
}

export default Receipt;