import React from 'react';
import { connect } from 'react-redux';
import { changeCheckoutStep } from '../../store/Actions';

function BookingButton({
    step = "LocationSelect",
    disabled = false,
    children,
    onChangeCheckoutStep
}) {
    const handleOnClick = ()  => {
        onChangeCheckoutStep(step);
    }
    return (
        <button className={`card-button
            ${disabled && ("disabled")}`}
            onClick={handleOnClick}
            disabled={disabled}
            >
                {children}
        </button>
    )
}

const mapDispatchToProps = (dispatch) => ({
    onChangeCheckoutStep: step => dispatch(changeCheckoutStep(step)),
});

export default connect(null, mapDispatchToProps)(BookingButton);
