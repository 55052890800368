import React from 'react';
import './../App.css';

import Header from './../components/Header';
import Footer from './../components/Footer';
import BookingForm from './../components/BookingForm/BookingForm';
import CookieCard from './../components/CookieCard';

function Home() {

    return (

    <div className="">
        <div className="bg-gray-200/60 relative">
            <Header/>

<div className="hero-section pb-10">
    <div className="flex px-6 pt-14 lg:px-8 m-auto max-h-full min-h-screen max-w-7xl">
        <div className="min-h-full mx-auto max-w-2xl
            pt-40
            sm:pt-24
            md:pt-40
            lg:pt-40
            hero-content">
            <div className="text-center">
                <h1 className="text-4xl font-bold tracking-tight Sext-Dray-900 sm:Fext-6xl">
                    Local Deliveries,<br/> Same-Day and Eco-Friendly
                </h1>
                <p className="mt-6 text-lg leading-8 bg-white/70">
                    Only servicing Sacramento City, Downtown
                </p>
            </div>
            <div className="flex mt-10 items-center justify-center gap-x-6">
                <div className="">
                    <a href="#schdulePickup"
                        className="card-button slide_left">
                        Schedule Now
                    </a><br/><br/>
                    <span className="text-sm font-semibold leading-6 text-gray-900 ">Learn more <span aria-hidden="true">→</span></span>
                </div>
            </div>
        </div>
    </div>
</div>

<CookieCard />
<div className="">
    <div className="shadow-lg max-w-7xl mx-auto">
        <div className="grid grid-cols-1
            lg:grid-cols-2
            md:grid-cols-3
            sm:grid-cols-3
            max-full max-w-7xl m-auto
            -mt-5 rounded-t-md
            overflow-hidden
            ">
            <BookingForm />
            <div className="
                md:col-span-1 sm:col-span-1
                city-map-section"></div>
        </div>

        <div className="max-w-7xl m-auto pt-10 bg-red-100/80">
            <div id="services" className="min-h-100 text-4xl pt-10 pb-5 px-20 m-auto max-w-7xl">
                How we deliver your packages and Parcels!
            </div>
            <div className="grid sm:grid-cols-3 gap-10 min-h-100 text-2xl pt-5 pb-14 px-20 m-auto">
                <div>
                    <img
                        className="w-auto mb-5"
                        src="./holdingPackage4.jpeg"
                        alt=""/>
                    We bike to your location and pick up delivery
                </div>
                <div>
                    <img
                        className="w-auto mb-5"
                        src="./ridingBike5.jpeg"
                        alt=""/>
                    Bike to delivery location
                </div>
                <div>
                    <img
                        className="w-auto mb-5"
                        src="./phoneNotification4.jpeg"
                        alt=""/>
                    Deliver package and send you a notification
                </div>
            </div>
        </div>

        <div className="bg-blue-100/80 max-w-7xl m-auto">
            <div id="services" className="min-h-100 text-4xl pt-10 pb-5 px-20 m-auto max-w-7xl">
                What Can We Deliver?
            </div>
            <div className="grid md:grid-cols-12 sm:grid-cols-4 gap-5 min-h-100 text-xl pt-5 m-auto">
                <div className="md:col-start-3 md:col-span-2">
                    <img
                        className="w-auto mb-5"
                        src="./legalDocuments.jpeg"
                        alt=""/>
                    Legal Documents
                </div>
                <div className="md:col-span-2">
                    <img
                        className="w-auto mb-5"
                        src="./package.jpeg"
                        alt=""/>
                    Packages<br/>
                    Less Than 5 pounds
                </div>
                <div className="md:col-span-2">
                    <img
                        className="w-auto mb-5"
                        src="./legalDocuments2.jpeg"
                        alt=""/>
                    Important Letters
                </div>
                <div className="md:col-span-2">
                    <img
                        className="w-auto mb-5"
                        src="./groceries.jpeg"
                        alt=""/>
                    Other
                </div>
            </div>
            <div className="text-left text-lg p-10 px-20">
                Fixie Devliery is a same-day delivery service that uses bicycles to deliver
                mail and packages. We are a sustainable and efficient way to get your
                deliveries done quickly and reliably.
                <br/><br/>
                We can meet your business or personal needs with deliveries that are big
                enough to fit in our carrier bag. This includes everything from small
                packages and documents to larger items like groceries and flowers.
            </div>
            <div className="flex flex-col pb-10">
                <a href="#schdulePickup"
                    className="card-button slide_left">
                    Schedule Now
                </a>
            </div>
        </div>

        <div id="about" className="max-w-7xl m-auto min-h-100 text-lg
            pt-10 pb-10 px-20 text-left bg-white">
            <div className="text-4xl text-center pb-10">About Us </div>

            <div className="sm:grid sm:grid-cols-3 gap-10">
                <div className="col-span-3 sm:col-span-3 md:col-span-2">
                    Fixie Delivery is a new delivery service in Sacramento City
                    that is committed to providing fast, reliable, and eco-friendly
                    delivery of parcels. We use bicycles to deliver your packages,
                    which means that we can get them to you quickly and efficiently,
                    without adding to air pollution or traffic congestion.
                    <br/>
                    <br/>
                    We are also a customer-first business. We understand that your
                     time is valuable, so we work hard to get your packages to you
                      on time and in perfect condition. We also offer a variety of
                      services to meet your needs, including same-day delivery,
                      scheduled delivery, and rush delivery.
                    <br/>
                    <br/>
                    If you are looking for a fast, reliable, and eco-friendly way
                    to deliver your packages in Sacramento City, then Bike Couriers
                    is the perfect choice for you. Contact us today to learn more
                    about our services and to get a quote.
                    <br/>
                    <br/>
                    Use the form above to get a free quote!
                </div>
                <div className="sm:col-span-1 col-span-3">
                    <img
                        className="h-fit mb-5"
                        src="./businessStoreFront2.jpeg"
                        alt=""/>
                </div>
            </div>
        </div>

        <div className="max-w-7xl m-auto lg:grid lg:grid-cols-3 gap-10 text-2xl
            py-10 px-20 min-h-screen max-w-7xl m-auto bg-green-100">
            <div className="lg:col-start-2 lg:col-span-1 text-3xl pb-5">
                Testimonials
                <img
                    className="w-1/2 m-auto"
                    src="./testimonial6.jpeg"
                    alt=""/>
            </div>
            <div className="bg-blue-100 min-h-80 col-span-1 col-start-1 p-10 mb-10">
                <div className="text-base">
                    Fixie Delivery is the best courier service I've ever used!
                    They're fast, reliable, and eco-friendly.
                    My packages are always delivered on time and in perfect condition.
                    I highly recommend them to anyone looking for a reliable
                    and sustainable delivery service.
                </div>
                <div className="grid grid-cols-5">
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                </div>
            </div>
            <div className="bg-blue-100 min-h-80 col-span-1 p-10 mb-10">
                <div className="text-base">
                    Fixie Delivery is the best! Fast, reliable, and eco-friendly.
                    My packages always arrive on time and in perfect condition. I highly recommend them!
                </div>
                <div className="grid grid-cols-5">
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                </div>
            </div>
            <div className="bg-blue-100 min-h-80 col-span-1 p-10 mb-10">
                <div className="text-base">
                    Fixie Delivery: Fast, reliable, and green.
                    I love that my packages are delivered by bike, and they always arrive on time.
                </div>
                <div className="grid grid-cols-5 ">
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                    <img
                    className="w-8 m-auto"
                    src="./star2.png"
                    alt=""/>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</div>
</div>

    </div>
  );
}

export default Home;
