import { combineReducers } from 'redux';
import CheckoutReducer from '../reducers/CheckoutReducer';
import FirestoreReducer from '../reducers/FirestoreReducer';
import UserSlice from '../reducers/UserSlice';

const rootReducer = combineReducers({
    checkout: CheckoutReducer,
    firestore: FirestoreReducer,
    userSlice: UserSlice,
});

export default rootReducer;
