import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import DaySelect from "../../components/BookingForm/DaySelect";
import HalfHourSelect from "../../components/BookingForm/HalfHourSelect";
import moment from"moment";
import {
    checkoutUpdateData,
    firebaseDeliveryPickupTimes,
} from '../../store/Actions';

function DateTimeSelect({
    field,
    checkout,
    deliveryAndPickupTimes,
    doCheckoutUpdateData,
    doFirebaseDeliveryPickupTimes,
}) {

    const [ selectedDay, setSelectedDay ] = useState(null);
    const [ selectedHour, setSelectedHour ] = useState(null);
    const [ displayDay, setDisplayDay ] = useState('');
    const [ filteredDates, setFilteredDates ] = useState(null);

    const memoizedDoFirebasePickups = useCallback(() => {
        doFirebaseDeliveryPickupTimes({})
    }, [doFirebaseDeliveryPickupTimes]);

    useEffect(() => {
        memoizedDoFirebasePickups()
    }, [memoizedDoFirebasePickups]);

    useEffect(() => {
        setFilteredDates(deliveryAndPickupTimes);
    }, [deliveryAndPickupTimes])


    // if day is fully booked, disabled on day ui
    // if days are booked in a week, auto move to the next week


    useEffect (() => {
        if (selectedDay === '' || selectedHour === '') return;
        doCheckoutUpdateData(field, moment(`${selectedDay} ${selectedHour}`, "YYYY-MMM-D h:mm A").format("YYYY-MMM-D h:mm A"));
    }, [selectedDay, selectedHour, doCheckoutUpdateData, field])

    useEffect(() => {
        if (checkout[field] !== '') {
            let currentPickupTime = moment(checkout[field], "YYYY-MMM-D h:mm A");
            setSelectedDay(currentPickupTime.format("YYYY-MMM-D"));
            setSelectedHour(currentPickupTime.format("h:mm A"));
            setDisplayDay(currentPickupTime.format("MMM D"));
        }
    },[field, checkout]);

    useEffect(() => {
        doCheckoutUpdateData(field, '');
    }, [setSelectedDay, doCheckoutUpdateData, field]);

    return (
        <>
            <div className="flex text-center pt-4">
                <p className="m-auto">Select a day for {field}</p>
            </div>

            <DaySelect
                field={field}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                setDisplayDay={setDisplayDay}
                setSelectedHalfHour={setSelectedHour}
                />

            {displayDay !== '' && (
                <>
                    <div className="float-center text-center pt-4">
                        <p className="m-auto">Day set for {displayDay}, select a time slot below</p>
                    </div>

                    <HalfHourSelect
                        selectedDay={selectedDay}
                        selectedHalfHour={selectedHour}
                        setSelectedHalfHour={setSelectedHour}
                        filteredDates={filteredDates}
                        field={field}
                        />

                    {selectedHour && (
                        <div className="text-xl text-center">
                            {field} set for {displayDay} at {selectedHour}.
                        </div>
                    )}
                </>

            )}
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doCheckoutUpdateData: (index, data) => dispatch(checkoutUpdateData(index, data)),
    doFirebaseDeliveryPickupTimes: () => dispatch(firebaseDeliveryPickupTimes()),
});

const mapStateToProps = (state) => ({
    checkout: state.checkout,
    deliveryAndPickupTimes: state.firestore.deliveryAndPickupTimes,
});

export default connect(mapStateToProps, mapDispatchToProps)(DateTimeSelect);
