import {
    FIREBASE_PICKUPS,
    FIREBASE_SET_PICKUPS,
    FIREBASE_SET_DELIVERY_PICKUP_TIMES,
} from '../store/Actions';

const initialState = {
    pickups: null,
    deliveryAndPickupTimes: null,
};

const FirestoreReducer = (state = initialState, action) => {
    switch(action.type) {
        case FIREBASE_SET_PICKUPS:
            return {
                ...state,
                pickups: action.payload,
            }
        case FIREBASE_PICKUPS:
            return {
                ...state,
            }
        case FIREBASE_SET_DELIVERY_PICKUP_TIMES:
            return {
                ...state,
                deliveryAndPickupTimes: action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}

export default FirestoreReducer;