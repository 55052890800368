import React from "react";
import { connect } from 'react-redux';
import Header from '../components/Header';
import Footer from './../components/Footer';

const CookiePolicy = () => {

    return (
        <div className="">
            <div className="bg-white">
                <Header/>
                <div className="bg-green-200/80 m-auto pt-32 pb-10">
                    <div className="flex flex-col min-h-screen max-w-4xl m-auto p-5 text-left">
                        <div className="flex flex-col p-5 m-5 bg-white rounded-lg shadow-lg">
                            <div className="text-4xl pb-5">
                                Cookie Policy
                            </div>
                            <div>

                                <b>What is a cookie?</b><br/><br/>

A cookie is a small text file that is stored on your
computer or mobile device when you visit a website. It contains
 information about your browsing activity on that website, such as
 the pages you visited, the links you clicked on, and the items you
 added to your cart. Cookies are used to remember your preferences and
 settings, and to improve your overall browsing experience.<br/><br/>

<b>How do we use cookies for Stripe payment and Stripe payment security?</b><br/>
<br/>
We use cookies to process Stripe payments securely and efficiently.
For example, we use cookies to store your payment information so that
you don't have to enter it every time you make a purchase. We also use
 cookies to detect and prevent fraudulent payments.<br/>
<br/>
<b>Here are some specific examples of how we use cookies for Stripe payment
and Stripe payment security:</b><br/>
<br/>
<b>To store your payment information:</b> When you make a purchase using Stripe,
we store your payment information in a secure cookie. This allows you to
checkout quickly and easily in the future without having to enter your
payment information again.<br/>
<b>To detect and prevent fraudulent payments:</b> We use cookies to monitor
your browsing activity and identify any suspicious activity. This
helps us to detect and prevent fraudulent payments.<br/>
<b>To improve your overall browsing experience:</b> We use cookies to
remember your preferences and settings, and to improve your overall
 browsing experience. For example, if you choose to log in to our website,
  we will use a cookie to remember your login information so that you don't
  have to enter it every time you visit.<br/>
  <br/>
<b>How to manage cookies</b><br/>

You can manage your cookie preferences in your browser settings.
Most browsers allow you to disable cookies altogether, or to block
certain types of cookies. However, disabling cookies may prevent you
from using some features of our website.<br/>
<br/>
<b>For more information on how to manage cookies, please consult the help
documentation for your browser.</b><br/>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default connect(null, null)(CookiePolicy);

