import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';

import AuthGuard from './guards/AuthGuard';
import GuestGuard from './guards/GuestGuard';
import Home from './pages/Home';
import Receipt from './pages/Receipt';
import CookiePolicy from './pages/CookiePolicy';
import Login from './pages/auth/Login';
import Admin from './pages/Admin';
import AdminSettings from './pages/admin/Settings';
import Register from './pages/auth/Register';

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route exact path="/register" element={<Register />} />
                    <Route exact path="/login" element={<GuestGuard><Login/></GuestGuard>} />
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/receipt" element={<Receipt />} />
                    <Route exact path="/cookie-policy" element={<CookiePolicy />} />
                    <Route exact path="/admin" element={<AuthGuard><Admin/></AuthGuard>} />
                    <Route exact path="/admin/settings" element={<AuthGuard><AdminSettings/></AuthGuard>} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
