import { takeLatest, put, call, select, delay } from 'redux-saga/effects';
import * as checkoutSelectors from '../selectors/CheckoutSelectors';
import {
    CHECKOUT_ADDRESS_SUGGESTION,
    CHECKOUT_ADDRESS_DISTANCE,
    CHECKOUT_CREATE_ORDER,
    CHECKOUT_GET_ORDER,
    CHECKOUT_GET_ORDER_BY_CLIENT_SECRET,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    FIREBASE_PICKUPS,
    FIREBASE_DELIVERY_PICKUP_TIMES,
    FIREBASE_SETTINGS,
    FIREBASE_SET_SETTINGS_SCHEDULE_ONLY_TODAY,
    FIREBASE_SET_ORDER_DELIVERY,
    FIREBASE_GET_CUSTOM_TOKEN,
    checkoutLoading,
    checkoutGoogleAddressSuggestions,
    checkoutUpdateData,
    checkoutUpdateRest,
    firebaseSetPickups,
    firebaseSetDeliveryPickupTimes,
} from '../store/Actions';
import {
    fetchGoogleSuggestAddressApi,
    fetchGoogleDistanceApi,
    fetchFirebaseLogin,
    fetchFirebaseLogout,
    fetchFirebasePickups,
    fetchFirebaseCreatePickup,
    fetchFirebaseUpdateOrderClientSecret,
    fetchFirebaseGetOrder,
    fetchFirebaseGetOrderByClientSecret,
    fetchFirebaseDeliveryPickupTimes,
    fetchFirebaseSetOrderDelivery,
    fetchStripeCreatePaymentIntent,
    fetchFirebaseSettings,
    fetchFirebaseSetSettingsScheduleOnlyToday,
    fetchFirebaseGetCustomToken,
} from '../api/Api';
import {
    setUser,
    setUserToken,
    clearUserData,
} from "../reducers/UserSlice";

function* getGoogleAPISuggestion(data) {
    yield put(checkoutLoading(true));
    yield delay(500);
    const googleSuggestions = yield call(fetchGoogleSuggestAddressApi, data.payload);
    yield put(checkoutGoogleAddressSuggestions(googleSuggestions.data.data.predictions));
    yield put(checkoutLoading(false));
}

function* getGoogleAPIDistance(data) {
    yield put(checkoutLoading(true));
    const googleDistance = yield call(fetchGoogleDistanceApi, data.payload);
    yield put(checkoutUpdateData('distance', googleDistance.data.data.distance.text));
    yield put(checkoutUpdateData('distanceValue', googleDistance.data.data.distance.value));
    yield put(checkoutUpdateData('duration', googleDistance.data.data.duration.text));
    yield put(checkoutUpdateData('durationValue', googleDistance.data.data.duration.value));
    const distanceArray = googleDistance.data.data.distance.text.split(" ");
    const price = parseFloat(distanceArray[0]) * 30;
    yield put(checkoutUpdateData('price', price));
    yield put(checkoutLoading(false));
}

function* getCreateOrder() {
    let userCheckout = yield select(checkoutSelectors.order);
    const orderId = yield call(fetchFirebaseCreatePickup, userCheckout);
    yield put(checkoutUpdateData('orderId', orderId.data.data));
    const clientSecret = yield call(fetchStripeCreatePaymentIntent, userCheckout.distance);
    yield put(checkoutUpdateData('clientSecret', clientSecret.data.data));
    yield call(fetchFirebaseUpdateOrderClientSecret, {
        id: orderId.data.data,
        clientSecret: clientSecret.data.data,
    });
}

function* getOrder(data) {
    yield call(fetchFirebaseGetOrder, data.payload);
}

function* getOrderByClientSecret(data) {
    const order = yield call(fetchFirebaseGetOrderByClientSecret, data.payload);
    yield put(checkoutUpdateRest(order.data.data));
}

function* getAuthLogin(data) {
    const login = yield call(fetchFirebaseLogin, data.payload);
    yield put(setUser(login.data));
}

function* getAuthLogout(data) {
    yield call(fetchFirebaseLogout);
    yield put(clearUserData());
}

function* getFirebasePickups(data) {
    const pickups = yield call(fetchFirebasePickups);
    yield put(firebaseSetPickups(pickups.data.data));
}

function* getFirebaseDeliveryPickupTimes() {
    const deliveryPickupTimes = yield call(fetchFirebaseDeliveryPickupTimes);
    yield put(firebaseSetDeliveryPickupTimes(deliveryPickupTimes.data.data));
    const settings = yield call(fetchFirebaseSettings);
    yield put(checkoutUpdateData('scheduleOnlyToday', settings.data.data.scheduleOnlyToday));
}

function* getFirebaseSettings() {
    const settings = yield call(fetchFirebaseSettings);
    yield put(checkoutUpdateData('scheduleOnlyToday',  settings.data.data.scheduleOnlyToday));
}

function* getFirebaseSetSettingsScheduleOnlyToday(data) {
    const settings = yield call(fetchFirebaseSetSettingsScheduleOnlyToday, data.payload);
    yield put(checkoutUpdateData('scheduleOnlyToday',  settings.data.data.scheduleOnlyToday));
}

function* getFirebaseGetCustomToken(data) {
    const token = yield call(fetchFirebaseGetCustomToken, data.payload);
    yield put(setUserToken(token.data.customToken));
}

function* getFirebaseSetOrderDelivery(data) {
    yield call(fetchFirebaseSetOrderDelivery, data.payload);
}

function* dataSaga() {
    yield takeLatest(CHECKOUT_ADDRESS_SUGGESTION, getGoogleAPISuggestion);
    yield takeLatest(CHECKOUT_ADDRESS_DISTANCE, getGoogleAPIDistance);
    yield takeLatest(CHECKOUT_CREATE_ORDER, getCreateOrder);
    yield takeLatest(CHECKOUT_GET_ORDER, getOrder);
    yield takeLatest(CHECKOUT_GET_ORDER_BY_CLIENT_SECRET, getOrderByClientSecret);

    yield takeLatest(AUTH_LOGIN, getAuthLogin);
    yield takeLatest(AUTH_LOGOUT, getAuthLogout);

    yield takeLatest(FIREBASE_PICKUPS, getFirebasePickups);
    yield takeLatest(FIREBASE_DELIVERY_PICKUP_TIMES, getFirebaseDeliveryPickupTimes);
    yield takeLatest(FIREBASE_SETTINGS, getFirebaseSettings);
    yield takeLatest(FIREBASE_SET_SETTINGS_SCHEDULE_ONLY_TODAY, getFirebaseSetSettingsScheduleOnlyToday);
    yield takeLatest(FIREBASE_SET_ORDER_DELIVERY, getFirebaseSetOrderDelivery);

    yield takeLatest(FIREBASE_GET_CUSTOM_TOKEN, getFirebaseGetCustomToken);
}

export default dataSaga;
