import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function CookieCard() {

    const [isAccepted, setIsAccepted] = useState(false);
    const handleOnClick = ()  => {
        console.log(isAccepted);
        setIsAccepted(true);
        console.log(isAccepted);
    }
    return (
        <>
            { isAccepted === false && (
                <div className="cookie-card">
                    <div className="text-xl mb-2">We value your privacy</div>
                    <div className="text-left mb-2">
                        We use cookies to enhance your browsing experience,
                        process stripe payments and analyze our traffic.
                        By clicking "Accept All", you consent to our use of cookies.
                        <br/><Link to="/cookie-policy">Cookie Policy</Link>
                    </div>
                    <button className="card-button"
                        onClick={handleOnClick}>
                        Accept All
                    </button>
                </div>
            )}
        </>
    )

}

export default CookieCard;