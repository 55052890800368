import axios from 'axios';

// Extract common function to make code more reusable and maintainable.
const fetchNetlifyFunction = (functionName, body = {}) => {
  return axios.post(`/.netlify/functions/${functionName}`, body);
};

// Use extracted function to refactor all fetch functions.
export const fetchUserIdApi = () => {
  return fetchNetlifyFunction('getFirebase');
};

export const fetchGoogleSuggestAddressApi = (address) => {
  return fetchNetlifyFunction('getGoogleMapsAddress', { address });
};

export const fetchGoogleDistanceApi = (address) => {
  return fetchNetlifyFunction('getGoogleMapsDistance', { address });
};

export const fetchFirebaseLogin = (user) => {
  return fetchNetlifyFunction('getFirebaseLogin', { user });
};

export const fetchFirebaseLogout = () => {
  return fetchNetlifyFunction('getFirebaseLogout');
};

export const fetchFirebasePickups = () => {
  return fetchNetlifyFunction('getFirebasePickups');
};

export const fetchFirebaseCreatePickup = (userCheckout) => {
  return fetchNetlifyFunction('getFirebaseCreatePickup', { userCheckout });
};

export const fetchStripeCreatePaymentIntent = (distance) => {
  return fetchNetlifyFunction('getStripeCreatePaymentIntent', { distance });
};

export const fetchFirebaseGetOrder = (checkoutId) => {
  return fetchNetlifyFunction('getFirebaseGetOrder', { checkoutId });
};

export const fetchFirebaseUpdateOrderClientSecret = (data) => {
  return fetchNetlifyFunction('getFirebaseUpdateOrderClientSecret', data);
};

export const fetchFirebaseGetOrderByClientSecret = (data) => {
  return fetchNetlifyFunction('getFirebaseGetOrderByClientSecret', { data });
};

export const fetchFirebaseDeliveryPickupTimes = () => {
  return fetchNetlifyFunction('getFirebaseDeliveryPickupTimes');
};

export const fetchFirebaseSettings = () => {
  return fetchNetlifyFunction('getFirebaseSettings');
};

export const fetchFirebaseSetSettingsScheduleOnlyToday = (data) => {
  return fetchNetlifyFunction('getFirebaseSetSettingsScheduleOnlyToday', data);
};

export const fetchFirebaseGetCustomToken = (data) => {
  return fetchNetlifyFunction('getFirebaseGetCustomToken', { data });
};

export const fetchFirebaseSetOrderDelivery = (data) => {
  return fetchNetlifyFunction('getFirebaseSetOrderDelivery', { data });
};
