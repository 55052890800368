import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    authLogout,
    firebaseSettings,
    firebaseSetSettingsScheduleOnlyToday,
} from '../../store/Actions';

const Settings = ({
    doAuthLogout,
    doFirebaseSettings,
    doFirebaseSetSettingsScheduleOnlyToday,
    pickups,
    state,
    checkout,
    user,
}) => {
    const [scheduleOnlyTodaySetting, setScheduleOnlyTodaySetting] = useState(checkout.scheduleOnlyToday);
    const memoizedDoFirebaseSettings = useCallback(() => {
        doFirebaseSettings({})
    }, [doFirebaseSettings]);

    useEffect(() => {
        memoizedDoFirebaseSettings()
    }, [memoizedDoFirebaseSettings]);

    const handleLogout = () => {
        doAuthLogout();
    }

    const handleCheckboxScheduleSettingsChange = () => {
        setScheduleOnlyTodaySetting(!scheduleOnlyTodaySetting);
        doFirebaseSetSettingsScheduleOnlyToday({
            setting: !scheduleOnlyTodaySetting,
            accessToken: user.customToken
        });
    }

    useEffect(() => {
        setScheduleOnlyTodaySetting(checkout.scheduleOnlyToday);
    }, [checkout.scheduleOnlyToday]);

    return (
        <div className="bg-gray-200/50 min-h-screen">
            <div className="flex bg-gray-300/50 shadow-lg max-w-2xl m-auto p-5">
                <Link to="/admin" className="card-button float-left">Home</Link>
                <Link to="/admin/settings" className="card-button float-left">Settings</Link>
                <button className="card-button float-right" onClick={handleLogout}>Logout</button>

            </div>
            <div className="flex flex-col bg-gray-300/50 shadow-lg mt-10 max-w-2xl m-auto p-5">
                <div>
                    Settings
                </div>
            </div>

            <div className="flex flex-col bg-gray-300/50 shadow-lg mt-10 max-w-2xl m-auto p-5">
                <input type="checkbox" id="scheduleOnlyToday" name="scheduleOnlyToday"
                    onChange={handleCheckboxScheduleSettingsChange}
                    checked={scheduleOnlyTodaySetting}
                    />
                <label htmlFor="scheduleOnlyToday"> Set to scheduling only today</label>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doAuthLogout: () => dispatch(authLogout()),
    doFirebaseSettings: () => dispatch(firebaseSettings()),
    doFirebaseSetSettingsScheduleOnlyToday: (setting) => dispatch(firebaseSetSettingsScheduleOnlyToday(setting)),
});


const mapStateToProps = (state) => ({
    pickups: state.firestore.pickups,
    state: state.firestore,
    checkout: state.checkout,
    user: state.userSlice,
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
