import React from 'react';
import BookingButton from '../../components/BookingForm/BookingButton';

function Login() {

    return (
        <div className="card">
            <div>
                <span className="text-4xl">
                    Login in to your Account
                </span>
            </div>
            <div className="pt-10 grid grid-cols-6 gap-2">

                <div className="float-left
                    col-span-4 col-start-2
                    sm:col-span-4 sm:col-start-2
                    md:col-span-4 md:col-start-2
                    lg:col-span-4 lg:col-start-2
                    ">
                    <div className="mb-5">
                        <input type="text"
                            placeholder="Email or Username"
                            className="placeholder:italic placeholder:text-slate-400 block
                            w-auto border-2 border-slate-300 py-2 shadow-sm
                            focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1
                            sm:text-lg justify-self-center text-center w-full"
                        />
                    </div>
                    <div>
                        <input type="password"
                            placeholder="Password"
                            className="placeholder:italic placeholder:text-slate-400 block
                            w-auto border-2 border-slate-300 py-2 shadow-sm
                            focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1
                            sm:text-lg justify-self-center text-center w-full"
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-col align-items-center pt-5 pb-2 align-items-center">
                <BookingButton step={2}>
                    Login
                </BookingButton>
            </div>
            <div className="flex flex-col text-center">
                <span className="text-sm font-semibold text-gray-900 text-center">
                    Schedule a Delivery
                    <span aria-hidden="true">→</span>
                </span>
            </div>

            <div className="flex grid grid-cols-3 my-5 mr-5">
                <div className="p-4 border border-2 border-green-600/50 rounded-lg bg-blue-100">
                    <div className="grid grid-cols-6">
                        <div className="m-auto">
                            <img
                                className="w-auto m-auto"
                                src="./one.png"
                                alt=""/>
                        </div>
                        <div className="col-span-5 m-auto">
                            Location
                        </div>
                    </div>
                </div>
                <div className="p-4 border border-1 border-gray-300/50 rounded-lg bg-gray-100">
                    <div className="grid grid-cols-6">
                        <div className="m-auto">
                            <img
                                className="w-auto m-auto"
                                src="./twoOff.png"
                                alt=""/>
                        </div>
                        <div className="col-span-5 m-auto">
                            Schedule
                        </div>
                    </div>
                </div>
                <div className="p-4 border border-1 border-gray-300/50 rounded-lg bg-gray-100">
                    <div className="grid grid-cols-6">
                        <div className="m-auto">
                            <img
                                className="w-auto m-auto"
                                src="./threeOff.png"
                                alt=""/>
                        </div>
                        <div className="col-span-5 m-auto">
                            Payment
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Login;