import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';

import BookingButton from '../../components/BookingForm/BookingButton';
import BookingInput from '../../components/BookingForm/BookingInput';
import CheckoutStepsAndRecipt from '../../components/BookingForm/CheckoutStepsAndRecipt';
import {
    checkoutAddressSuggestion,
    checkoutUpdateData,
} from '../../store/Actions';

function PickupSuggestions({
    checkout,
    googleSuggestions,
    doCheckoutAddressSuggestion,
    doCheckoutUpdateData,
}) {
    const [ buttonDisabled, setButtonDisabled ] = useState(true);
    const memoizedDoCheckoutAddressSuggestion = useCallback(() => {
        return doCheckoutAddressSuggestion(checkout.pickupLocation)
    }, [checkout.pickupLocation, doCheckoutAddressSuggestion]);

    useEffect(() => {
        memoizedDoCheckoutAddressSuggestion()
    }, [memoizedDoCheckoutAddressSuggestion]);

    const handleOnClick = (event) => {
        doCheckoutUpdateData('pickupLocationSuggested', event.currentTarget.dataset.id);
        doCheckoutUpdateData('origins', event.currentTarget.dataset.placeId);
    }

    useEffect(() => {
        if (checkout.pickupLocationSuggested !== '') setButtonDisabled(false);
    }, [checkout.pickupLocationSuggested]);

    return (
        <div className="card">
            <div>
                <span className="text-4xl">
                    Set Pickup Location
                </span>
            </div>

            <CheckoutStepsAndRecipt/>

            <div className="">
                <p>Select an address that best matches the address you entered.</p>
                Pickup Address You Entered:
                <BookingInput field="pickupLocation" placeholder="Set Pickup Location" disabled={false} />
            </div>

            {checkout.isLoading &&
                <div className="pt-10 pb-10">
                    <img src="./spinner.png" alt="loading"
                        className="object-contain w-10 m-auto animate-spin"/>
                </div>
            }
            { !checkout.isLoading && googleSuggestions.length === 0 &&
                <div className="pt-5 pb-5">
                    This address is not serviced by Bike Couriers
                </div>
            }
            { !checkout.isLoading && googleSuggestions.length !== 0 &&
                <div className="suggestion-choices">
                    {
                        Object.entries(googleSuggestions).map((suggestion, index) => {
                            return (
                                <div key={index}
                                    className={`suggestion-choice
                                        ${checkout.pickupLocationSuggested === suggestion[1].description && 'active'}
                                    `}
                                    data-id={suggestion[1].description}
                                    data-place-id={suggestion[1].place_id}
                                    onClick={handleOnClick}
                                    >
                                    {checkout.pickupLocationSuggested === suggestion[1].description && (
                                        <>
                                            <img
                                                className="w-5 h-5"
                                                src="./checkmark.png"
                                                alt=""/>
                                        </>
                                    )}
                                    {suggestion[1].description}
                                </div>
                            )
                        })
                    }
                </div>
            }

            <div className="grid grid-cols-2 gap 2 align-items-center pb-5 align-items-center">
                <BookingButton step="LocationSelect">
                    Back
                </BookingButton>
                <BookingButton step="DeliverySuggestions" disabled={buttonDisabled}>
                    Continue
                </BookingButton>
            </div>

        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doCheckoutAddressSuggestion: (address) => dispatch(checkoutAddressSuggestion(address)),
    doCheckoutUpdateData: (index, data) => dispatch(checkoutUpdateData(index, data)),
});

const mapStateToProps = (state) => ({
    checkout: state.checkout,
    googleSuggestions: state.checkout.googleSuggestions,
});

export default connect(mapStateToProps, mapDispatchToProps)(PickupSuggestions);
