import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div className="">
            <footer className="bg-white m-auto max-w-7xl md:grid md:grid-cols-3 bg-white p-10">
                <div className="flex lg:flex-1 md:col-start-1">
                    <Link to="/" className="-m-1.5 p-1.5">
                        <img className="w-10 float-left"
                            src="/logo2.png"
                            alt=""/>
                        <span className="float-right pt-2">Fixie Delivery</span>
                    </Link>
                </div>
                <div className="lg:flex lg:gap-x-12 md:col-start-3">
                    <Link to="/" className="text-sm font-semibold leading-6 text-gray-900">Home</Link>
                    <Link to="/#about" className="text-sm font-semibold leading-6 text-gray-900">About</Link>
                    <Link to="/#services" className="text-sm font-semibold leading-6 text-gray-900">Services</Link>
                </div>
            </footer>
        </div>
    )
}

export default Footer;
