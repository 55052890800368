import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
const stepLevelMap = {
    login: 0,
    LocationSelect: 1,
    PickupSuggestions: 2,
    DeliverySuggestions: 3,
    AddressDistance: 3,
    ContactInformation: 4,
    SchedulePickup: 5,
    ScheduleDelivery: 6,
    Payment: 7,
    Receipt: 8,
};

function LocationSelect({
    checkout,
}) {

    const [ checkoutLevel, setCheckoutLevel ] = useState(stepLevelMap[checkout.checkoutStep]);
    useEffect(() => {
        setCheckoutLevel(stepLevelMap[checkout.checkoutStep]);
    }, [checkout.checkoutStep]);

    return(
        <>
            <div className="">
                <div className="flex grid grid-cols-3 my-5">
                    <div className={`checkout-step first
                        ${ checkoutLevel === 1 && ("active")}
                        ${ checkoutLevel < 4 & checkoutLevel > 1 && ("almost")}
                        ${ checkoutLevel >= 4 && ("complete")}
                        `}>
                        <div className="grid grid-cols-6">
                            <div className="m-auto">
                                <img
                                    className="w-auto m-auto"
                                    src="./one.png"
                                    alt=""/>
                            </div>
                            <div className="col-span-5 m-auto">
                                Location
                            </div>
                        </div>
                    </div>

                    <div className={`checkout-step second
                        ${ checkoutLevel === 4 && ("active")}
                        ${ checkoutLevel === 6 | checkoutLevel === 5 && ("almost")}
                        ${ checkoutLevel >= 7 && ("complete")}
                        `}>
                        <div className="grid grid-cols-6">
                            <div className="m-auto">
                                { checkoutLevel >= 7 && (
                                    <img
                                        className="w-auto m-auto"
                                        src="./two.png"
                                        alt=""/>
                                )}
                                { checkoutLevel < 7 && (
                                    <img
                                        className="w-auto m-auto"
                                        src="./twoOff.png"
                                        alt=""/>
                                )}
                            </div>
                            <div className="col-span-5 m-auto">
                                Schedule
                            </div>
                        </div>
                    </div>

                    <div className={`checkout-step third
                        ${ checkoutLevel === 7 && ("active")}
                        ${ checkoutLevel >= 8 && ("complete")}
                        `}>
                        <div className="grid grid-cols-6">
                            <div className="m-auto">
                                <img
                                    className="w-auto m-auto"
                                    src="./threeOff.png"
                                    alt=""/>
                            </div>
                            <div className="col-span-5 m-auto">
                                Payment
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    checkout: state.checkout,
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelect);


