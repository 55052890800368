import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from"moment";

import { checkoutUpdateData } from '../../store/Actions';

function DaySelect({
    field,
    checkout,
    selectedDay,
    setSelectedHalfHour,
    setSelectedDay,
    setDisplayDay,
    doCheckoutUpdateData,
}) {

    const [ currentWeek, setCurrentWeek ] = useState(0);
    const [ weekTime, setWeekTime ] = useState([]);
    const [ filteredDays, setFilteredDays ] = useState([]);

    const weekTimeSpan = (adjuster = 0) =>
        Array(7)
            .fill()
            .map((_, index) =>
                moment()
                .day((adjuster * 7 ) +  index)
                .format("YYYY-MMM-D"));

    const previousWeek = () => {
        let currentWeekSubtraction = currentWeek - 1;
        if (currentWeekSubtraction < 0) currentWeekSubtraction = 0;
        setCurrentWeek(currentWeekSubtraction);
    };

    const nextWeek = () => {
        let currentWeekAddition = currentWeek + 1;
        if (currentWeekAddition > 2) currentWeekAddition = 2;
        setCurrentWeek(currentWeekAddition);
    }

    const handleSetDayActive = (event) => {
        setSelectedDay(event.currentTarget.dataset.id);
        if (moment(event.currentTarget.dataset.id, "YYYY-MMM-D").diff(moment(selectedDay, "YYYY-MMM-D")) !== 0) {
            setSelectedHalfHour(null);
        }
        setDisplayDay(moment(event.currentTarget.dataset.id).format("MMM D"));
    }

    // generate span of time to select, days and half hours
    useEffect(() =>  {
        const weekTimeAdjsuted = weekTimeSpan(currentWeek);
        setWeekTime(weekTimeAdjsuted);
    }, [currentWeek]);

    // filter days until current
    useEffect(() => {
        let filteredDaysWorking = [];
        weekTime.map((day) => {
            if (moment().subtract(1, "day").diff(moment(day, "YYYY-MMM-D")) >= 0) {
                filteredDaysWorking.push(moment(day).format("YYYY-MMM-D"));
            }
            return true;
        });
        if (field === "deliveryTime") {
            weekTime.map((day) => {
                if (moment(checkout.pickupTime, "YYYY-MMM-D h:mm A").subtract(1, "day").diff(moment(day, "YYYY-MMM-D")) >= 0) {
                    filteredDaysWorking.push(moment(day).format("YYYY-MMM-D"));
                }
                return true;
            });
        }
        if (checkout.scheduleOnlyToday) {
            weekTime.map((day) => {
                if (moment().diff(moment(day, "YYYY-MMM-D")) <= 1) {
                    filteredDaysWorking.push(moment(day).format("YYYY-MMM-D"));
                }
                return true;
            });
        }
        console.log(checkout);
        setFilteredDays(filteredDaysWorking);
    }, [weekTime, currentWeek, checkout, field, checkout.scheduleOnlyToday]);

    return(
        <>
            <div className="flex grid grid-cols-8 pt-5">
                <div className="col-span-1 col-start-1 m-auto">
                    <button className={`day-date-select-scroll
                        ${currentWeek === 0 && ("disabled")}`}
                        onClick={previousWeek}
                        disabled={!currentWeek}
                        >&lt;</button>
                </div>
                <div className="col-span-6 col-start-2
                    border-2 border-gray-200 bg-gray-100/50
                    rounded-md">
                    <div className="flex grid grid-cols-7
                    border-b-2 border-gray-200 bg-gray-300/50
                    text-center text-sm rounded-tl-sm rounded-tr-sm">
                        <div className="p-2">Sun</div>
                        <div className="p-2">Mon</div>
                        <div className="p-2">Tue</div>
                        <div className="p-2">Wed</div>
                        <div className="p-2">Thu</div>
                        <div className="p-2">Fri</div>
                        <div className="p-2">Sat</div>
                    </div>
                    <div className="flex grid grid-cols-7
                        text-center">
                        {weekTime.map((day, index) => {
                            let dayPieces = day.split("-");
                            let disabled = false;
                            let active = false;
                            if (filteredDays.includes(`${day}`)) disabled = true;
                            if (day === selectedDay) active = true;
                            return (
                                <button key={dayPieces[2]}
                                    className={`day-date-select ${active && ("active")} ${disabled && ("disabled")}`}
                                    disabled={disabled}
                                    onClick={handleSetDayActive}
                                    data-id={day}
                                    >
                                    <div className="text-xl">{dayPieces[2]}</div>
                                    <div>{dayPieces[1]}</div>
                                </button>
                            )
                        })}
                    </div>
                </div>
                <div className="col-span-1 col-start-8 m-auto">
                    <button className={`day-date-select-scroll
                        ${currentWeek === 2 && ("disabled")}`}
                        disabled={currentWeek === 2}
                        onClick={nextWeek}>&gt;</button>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doCheckoutUpdateData: (index, data) => dispatch(checkoutUpdateData(index, data)),
});

const mapStateToProps = (state) => ({
    checkout: state.checkout,
});

export default connect(mapStateToProps, mapDispatchToProps)(DaySelect);
