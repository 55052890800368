import React from 'react';
import { connect } from 'react-redux';
import { checkoutUpdateData } from '../../store/Actions';

function BookingInput({
    field,
    placeHolder,
    disabled = false,
    type = "text",
    data,
    doCheckoutUpdateData,
}) {
    const handleChange = (event) => {
        doCheckoutUpdateData(field, event.target.value);
    }

    return (
        <>
        {type === "text" &&
            <input type="text"
                placeholder={placeHolder}
                value={data[field]}
                onChange={handleChange}
                disabled={disabled}
                required
                className="placeholder:italic placeholder:text-slate-400 block
                w-auto border-2 border-slate-300 py-2
                focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1
                sm:text-lg justify-self-center text-center w-full
                disabled:bg-gray-200 disabled:text-gray-400 shadow-sm"
            />
        }
        {type === "textarea" &&
            <textarea
                placeholder={placeHolder}
                value={data[field]}
                onChange={handleChange}
                disabled={disabled}
                className="placeholder:italic placeholder:text-slate-400 block
                bg-white w-full border border-slate-300 rounded-md
                py-2 pl-9 pr-3 shadow-sm
                focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1
                sm:text-sm
                disabled:bg-gray-200 disabled:text-gray-400"
                ></textarea>
        }
        </>
    );  
}

const mapDispatchToProps = (dispatch) => ({
    doCheckoutUpdateData: (index, data) => dispatch(checkoutUpdateData(index, data)),
});

const mapStateToProps = (state) => ({
    data: state.checkout,
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingInput);
