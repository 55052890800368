import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import BookingButton from '../../components/BookingForm/BookingButton';
import BookingInput from '../../components/BookingForm/BookingInput';
import DateTimeSelect from '../../components/BookingForm/DateTimeSelect';
import CheckoutStepsAndRecipt from '../../components/BookingForm/CheckoutStepsAndRecipt';

function SchedulePickup({
    checkout,
    pickupLocation,
    deliveryLocation,
    setCheckoutStep
}) {
    const [ buttonDisabled, setButtonDisabled ] = useState(true);

    useEffect(() => {
        if (checkout.pickupTime === '') setButtonDisabled(true);
        if (checkout.pickupTime !== '') setButtonDisabled(false);
    }, [checkout.pickupTime]);

    return(
        <div className="card">
            <div className="">
                <span className="text-3xl">
                    Schedule Your Pickup
                </span>
            </div>

            <CheckoutStepsAndRecipt/>

            <DateTimeSelect field="pickupTime"/>

            <div className="text-2xl pt-10">
                <p>Special Pickup Instructions:</p>
            </div>
            <div className="flex px-10 py-5">
                <BookingInput field="pickupInstructions" placeHolder="Special Intructions" disabled={false} type="textarea"/>
            </div>
            <div className="flex grid grid-cols-3 align-items-center pt-5 align-items-center">
                <div className="col-span-1">
                    <BookingButton step="ContactInformation">
                        Go Back
                    </BookingButton>
                </div>
                <div className="col-span-2">
                    <BookingButton step="ScheduleDelivery" disabled={buttonDisabled}>
                        Set Pickup Time
                    </BookingButton>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    checkout: state.checkout,
});

export default connect(mapStateToProps, null)(SchedulePickup);

