import React from 'react';
import { connect } from 'react-redux';
import { changeCheckoutStep } from '../../store/Actions';

import BookingInput from '../../components/BookingForm/BookingInput';
import CheckoutStepsAndRecipt from '../../components/BookingForm/CheckoutStepsAndRecipt';

function LocationSelect({
    checkout,
    onChangeCheckoutStep
}) {
    const handleSubmit = (e) => {
        e.preventDefault();
        onChangeCheckoutStep("PickupSuggestions");
    }

    return(
        <div className="card">
            <div>
                <span className="text-4xl">
                    Schedule A Delivery
                </span>
            </div>

            <CheckoutStepsAndRecipt/>

            <div className="text-center">
                <span className="text-xl">
                    Using eco friendly means to accompolish inner city deliveries.
                    Same Day Delivery Only.<br/>
                    <b>Servicing only the Sacramento City Area</b></span>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="pt-10 grid grid-cols-6 gap-2">
                    <div className="float-right
                        col-span-1
                        sm:col-span-1
                        md:col-span-1
                        lg:col-span-1
                        ">
                        <img className="h-115 float-right"
                            src="./mapInputMarker.png"
                            alt=""/>
                    </div>
                    <div className="float-left
                        col-span-4 col-start-2
                        sm:col-span-4 sm:col-start-2
                        md:col-span-4 md:col-start-2
                        lg:col-span-4 lg:col-start-2
                        ">
                        <div className="mb-5">
                            <BookingInput field="pickupLocation" placeHolder="Pickup Location"/>
                        </div>
                        <div>
                            <BookingInput field="deliveryLocation" placeHolder="Delivery Location"/>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col align-items-center py-5 align-items-center">
                    <button className="card-button">
                        Continue
                    </button>
                    <span className="text-sm font-semibold text-gray-900 text-center">
                        Schedule a Delivery
                        <span aria-hidden="true">→</span>
                    </span>
                </div>
            </form>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    onChangeCheckoutStep: step => dispatch(changeCheckoutStep(step)),
});

const mapStateToProps = (state) => ({
    checkout: state.checkout,
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationSelect);

