import {
    PAGE_INIT,
    CHANGE_CHECKOUT_STEP,
    CHECKOUT_UPDATE_DATA,
    CHECKOUT_LOADING,
    CHECKOUT_GOOGLE_ADDRESS_SUGGESTIONS,
    CHECKOUT_UPDATE_REST,
} from '../store/Actions';

// const initialState = {
//     checkoutStep: "LocationSelect",
//     firstName: '',
//     lastName: '',
//     email: '',
//     phone: '',
//     pickupLocation: '',
//     pickupLocationSuggested: '',
//     pickupTime: '',
//     pickupInstructions: '',
//     origins: '',
//     deliveryLocation: '',
//     deliveryLocationSuggested: '',
//     deliveryTime: '',
//     deliveryInstructions: '',
//     destination: '',
//     distance: null,
//     isLoading: true,
//     googleSuggestions: null,
//     orderId: null,
//     clientSecret: null,
//     price: null,
//     isDelivered: false,
//     hasPayment: false,
//     scheduleOnlyToday: true,
// };

const initialState = {
    checkoutStep: "LocationSelect",
    firstName: 'Jaron',
    lastName: 'King',
    email: 'jaronjking@gmail.com',
    phone: '7608986275',
    pickupLocation: '1220 D Street',
    pickupLocationSuggested: '1220 D Street, Sacramento, CA, USA',
    pickupTime: '2023-Oct-16 10:00 AM',
    pickupInstructions: '',
    origins: 'ChIJObkggtTQmoARwnqHcV27BVU',
    deliveryLocation: '1420 J Street',
    deliveryLocationSuggested: '1420 J Street, Sacramento, CA, USA',
    deliveryTime: '2023-Oct-16 10:30 AM',
    deliveryInstructions: '',
    destination: 'EiIxNDIwIEogU3RyZWV0LCBTYWNyYW1lbnRvLCBDQSwgVVNBIjESLwoUChIJ0YBiE9fQmoAR74sD5mHGJNgQjAsqFAoSCctTPpWV0JqAEfVfeIsvTNn9',
    distance: null,
    isLoading: true,
    googleSuggestions: null,
    orderId: null,
    clientSecret: null,
    price: null,
    isDelivered: false,
    hasPayment: false,
    scheduleOnlyToday: true,
};


const CheckoutReducer = (state = initialState, action) => {
    switch(action.type) {
        case PAGE_INIT:
            return {
                ...state,
                checkoutStep: "LocationSelect"
            }
        case CHANGE_CHECKOUT_STEP:
            return {
                ...state,
                checkoutStep: action.payload,
            }
        case CHECKOUT_UPDATE_DATA:
            return {
                ...state,
                [action.payload.index]: action.payload.data,
            };
        case CHECKOUT_LOADING:
            return {
                ...state,
                isLoading: action.payload,
            }
        case CHECKOUT_GOOGLE_ADDRESS_SUGGESTIONS:
            return {
                ...state,
                googleSuggestions: action.payload,
            }
        case CHECKOUT_UPDATE_REST:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return {
                ...state,
            }
    }
}

export default CheckoutReducer;