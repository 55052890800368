export const PAGE_INIT = 'PAGE_INIT';
export const CHANGE_CHECKOUT_STEP = "CHANGE_CHECKOUT_STEP";
export const CHECKOUT_UPDATE_DATA = "CHECKOUT_UPDATE_DATA";
export const CHECKOUT_ADDRESS_SUGGESTION = "CHECKOUT_ADDRESS_SUGGESTION"
export const CHECKOUT_LOADING = "CHECKOUT_LOADING";
export const CHECKOUT_GOOGLE_ADDRESS_SUGGESTIONS = "CHECKOUT_GOOGLE_ADDRESS_SUGGESTIONS";
export const CHECKOUT_ADDRESS_DISTANCE = "CHECKOUT_ADDRESS_DISTANCE";
export const CHECKOUT_CREATE_ORDER = "CHECKOUT_CREATE_ORDER";
export const CHECKOUT_GET_ORDER = "CHECKOUT_GET_ORDER";
export const CHECKOUT_UPDATE_CLIENT_SECRET = "CHECKOUT_UPDATE_CLIENT_SECRET";
export const CHECKOUT_GET_ORDER_BY_CLIENT_SECRET = "CHECKOUT_GET_ORDER_BY_CLIENT_SECRET";
export const CHECKOUT_UPDATE_REST = "CHECKOUT_UPDATE_REST";

export const INIT_LOGIN = "INIT_LOGIN";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const FIREBASE_PICKUPS = "FIREBASE_PICKUPS";
export const FIREBASE_SET_PICKUPS = "FIREBASE_SET_PICKUPS";
export const FIREBASE_DELIVERY_PICKUP_TIMES = "FIREBASE_DELIVERY_PICKUP_TIMES";
export const FIREBASE_SET_DELIVERY_PICKUP_TIMES = "FIREBASE_SET_DELIVERY_PICKUP_TIMES";
export const FIREBASE_SET_ORDER_DELIVERY = "FIREBASE_SET_ORDER_DELIVERY";
export const FIREBASE_SETTINGS = "FIREBASE_SETTINGS";
export const FIREBASE_SET_SETTINGS_SCHEDULE_ONLY_TODAY = "FIREBASE_SET_SETTINGS_SCHEDULE_ONLY_TODAY";
export const FIREBASE_GET_CUSTOM_TOKEN = "FIREBASE_GET_CUSTOM_TOKEN";

export const checkoutUpdateRest = (data) => ({
    type: CHECKOUT_UPDATE_REST,
    payload: data,
});

export const checkoutGetOrderByClientSecret = (data) => ({
    type: CHECKOUT_GET_ORDER_BY_CLIENT_SECRET,
    payload: data,
});

export const checkoutUpdateClientSecret = (data) => ({
    tpye: CHECKOUT_UPDATE_CLIENT_SECRET,
    payload: data
});

export const checkoutGetOrder = () => ({
    type: CHECKOUT_GET_ORDER,
});

export const checkoutCreateOrder = () => ({
    type: CHECKOUT_CREATE_ORDER,
});

export const firebaseSetPickups = (data) => ({
    type: FIREBASE_SET_PICKUPS,
    payload: data,
});

export const firebasePickups = (data) => ({
    type: FIREBASE_PICKUPS,
    payload: data,
});

export const firebaseDeliveryPickupTimes = () => ({
    type: FIREBASE_DELIVERY_PICKUP_TIMES,
});

export const firebaseSetDeliveryPickupTimes = (data) => ({
    type: FIREBASE_SET_DELIVERY_PICKUP_TIMES,
    payload: data,
});

export const firebaseSetOrderDelivery = (data) => ({
    type: FIREBASE_SET_ORDER_DELIVERY,
    payload: data,
});

export const firebaseSettings = () => ({
    type: FIREBASE_SETTINGS,
});

export const firebaseSetSettingsScheduleOnlyToday = (data) => ({
    type: FIREBASE_SET_SETTINGS_SCHEDULE_ONLY_TODAY,
    payload: data,
});

export const firebaseGetCustomToken = (data) => ({
    type: FIREBASE_GET_CUSTOM_TOKEN,
    payload: data,
});

export const authLogout = () => ({
    type: AUTH_LOGOUT,
});

export const authLogin = (data) => ({
    type: AUTH_LOGIN,
    payload: data,
});

export const changeCheckoutStep = (data) => ({
    type: CHANGE_CHECKOUT_STEP,
    payload: data,
});

export const checkoutUpdateData = (index, data) => ({
    type: CHECKOUT_UPDATE_DATA,
    payload: {
        index: index,
        data: data,
    }
});

export const checkoutAddressSuggestion = (data) => ({
    type: CHECKOUT_ADDRESS_SUGGESTION,
    payload: data,
});

export const checkoutAddressDistance = (data) => ({
    type: CHECKOUT_ADDRESS_DISTANCE,
    payload: data,
});

export const checkoutLoading = (data) => ({
    type: CHECKOUT_LOADING,
    payload: data,
});

export const checkoutGoogleAddressSuggestions = (data) => ({
    type: CHECKOUT_GOOGLE_ADDRESS_SUGGESTIONS,
    payload: data,
});

export const pageInit = (data) => ({
    type: PAGE_INIT,
    payload: data,
});