import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutStepsAndRecipt from '../../components/BookingForm/CheckoutStepsAndRecipt';
import CheckoutForm from '../../components/stripe/CheckoutForm';

import {
    checkoutCreateOrder
} from './../../store/Actions';

const stripePromise = loadStripe("pk_test_0l4JuDgVAZzk4MRPy4UtrTt6");

function Payment({
    doCheckoutCreateOrder,
    orderId,
    price,
    checkoutClientSecret,
}) {
    const [clientSecret, setClientSecret] = useState("");
    const memoizedDoFirebaseCreateOrder = useCallback(() => {
        doCheckoutCreateOrder();
    }, [ doCheckoutCreateOrder]);

    useEffect(() => {
        if (orderId === null) {
            memoizedDoFirebaseCreateOrder();
        }
    }, [memoizedDoFirebaseCreateOrder, orderId]);

    useEffect(() => {
        setClientSecret(checkoutClientSecret)
    }, [checkoutClientSecret]);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    return(
        <div className="card">
            <div className="text-center float-center">
                <span className="text-3xl">
                    Payment
                </span>
            </div>
            <CheckoutStepsAndRecipt/>

            {!clientSecret && (
                <div className="pt-10 pb-10">
                    Loading payment form...<br/>
                    <img src="./spinner.png" alt="loading"
                        className="object-contain w-10 m-auto animate-spin"/>
                </div>
            )}

            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <p className="text-xl">Cost for Pickup and Delivery: ${ price }</p>
                    <CheckoutForm />
                </Elements>
            )}
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doCheckoutCreateOrder: () => dispatch(checkoutCreateOrder()),
});

const mapStateToProps = (state) => ({
    orderId: state.checkout.orderId,
    checkoutClientSecret: state.checkout.clientSecret,
    price: state.checkout.price,
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
