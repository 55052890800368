import React, { useCallback, useEffect } from "react";
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
    checkoutGetOrderByClientSecret
} from './../store/Actions';
import BookingInput from './../components/BookingForm/BookingInput';
import Header from '../components/Header';
import Footer from './../components/Footer';

const Receipt = ({
    checkout,
    orderId,
    price,
    doCheckoutGetOrderByClientSecret,
}) => {
    const [searchParams] = useSearchParams();
    const clientSecret = searchParams.get("payment_intent_client_secret");
    // use callback to retrieve the checkout id,
    // finalize that checkout has paid,
    // display data about receipt
    const memoizedDoGetOrderByClientSecret = useCallback(() => {
        doCheckoutGetOrderByClientSecret(clientSecret);
    }, [ doCheckoutGetOrderByClientSecret, clientSecret]);

    useEffect(() => {
        memoizedDoGetOrderByClientSecret();
    }, [memoizedDoGetOrderByClientSecret, clientSecret]);
    return (
    <div className="">
        <div className="bg-white">
            <Header/>
            <div className="bg-green-200/80 m-auto pt-32 pb-10">
                <div className="flex flex-col min-h-screen max-w-xl m-auto p-5">
                    <div className="flex flex-col p-5 m-5 bg-white rounded-lg shadow-lg">
                        <div className="text-4xl pb-5">
                            Receipt
                        </div>
                    { clientSecret !== null && (
                        <>
                            <div className="text-xl">
                                Distance: { checkout.distance }<br/>
                                Cost: ${price}
                            </div>

                            <div>
                                <div className="flex grid grid-cols-1 pt-5 gap-2">
                                    <div>
                                        Pickup Location:
                                        <BookingInput field="pickupLocationSuggested" placeHolder="Pickup Location" disabled={true}/>
                                    </div>
                                    <div>
                                        Delivery Location:
                                        <BookingInput field="deliveryLocationSuggested" placeHolder="Delivery Location" disabled={true}/>
                                    </div>
                                </div>
                                <div className="flex grid grid-cols-2 pt-5 gap-2">
                                    <div>
                                        First Name:
                                        <BookingInput field="firstName" placeHolder="First Name" disabled={true}/>
                                    </div>
                                    <div>
                                        Last Name:
                                        <BookingInput field="lastName" placeHolder="Last Name" disabled={true}/>
                                    </div>
                                    <div>
                                        Email:
                                        <BookingInput field="email" placeHolder="Email" disabled={true}/>
                                    </div>
                                    <div>
                                        Phone:
                                        <BookingInput field="phone" placeHolder="Phone" disabled={true}/>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    Pickup Time:
                                    <BookingInput field="pickupTime" placeHolder="Pickup Time" disabled={true}/>
                                </div>

                                <div>
                                    <p>Special Pickup Instructions:</p>
                                    <BookingInput field="pickupInstructions" placeHolder="Special Pickup Instructions" disabled={true} type="textarea"/>
                                </div>
                            </div>
                            <div>
                                <div>
                                    Delivery Time:
                                    <BookingInput field="deliveryTime" placeHolder="Delivery Time" disabled={true}/>
                                </div>

                                <div>
                                    <p>Special Delivery Instructions:</p>
                                    <BookingInput field="deliveryInstructions" placeHolder="Special Delivery Instructions" disabled={true} type="textarea"/>
                                </div>
                            </div>
                        </>
                    )}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    </div>
    );
}


const mapDispatchToProps = (dispatch) => ({
    doCheckoutGetOrderByClientSecret: (clientSecret) => dispatch(checkoutGetOrderByClientSecret(clientSecret)),
});

const mapStateToProps = (state) => ({
    orderId: state.checkout.orderId,
    checkout: state.checkout,
    price: state.checkout.price
});

export default connect(mapStateToProps, mapDispatchToProps)(Receipt);

