import React from 'react';
import { connect } from 'react-redux';

import Login from '../../components/BookingForm/Login';
import ContactInformation from '../../components/BookingForm/ContactInformation';
import LocationSelect from '../../components/BookingForm/LocationSelect';
import PickupSuggestions from '../../components/BookingForm/PickupSuggestions';
import DeliverySuggestions from '../../components/BookingForm/DeliverySuggestions';
import AddressDistance from '../../components/BookingForm/AddressDistance';
import SchedulePickup from '../../components/BookingForm/SchedulePickup';
import ScheduleDelivery from '../../components/BookingForm/ScheduleDelivery';
import Payment from '../../components/BookingForm/Payment';
import Receipt from '../../components/BookingForm/Receipt';

function BookingForm({ checkout }) {
    return(
        <div id="schdulePickup" className="bg-green-100
            lg:col-span-1
            md:col-span-2
            sm:col-span-2
            col-span-1">
            <div className="bg-gray-50 m-5 sm:m-10 rounded-md shadow-md">
                {checkout.checkoutStep === 'login' && (
                    <Login />
                )}

                {checkout.checkoutStep === "LocationSelect" && (
                    <LocationSelect />
                )}

                {checkout.checkoutStep === "PickupSuggestions" && (
                    <PickupSuggestions />
                )}

                {checkout.checkoutStep === "DeliverySuggestions" && (
                    <DeliverySuggestions />
                )}

                {checkout.checkoutStep === "AddressDistance" && (
                    <AddressDistance />
                )}

                {checkout.checkoutStep === "ContactInformation" && (
                    <ContactInformation />
                )}

                {checkout.checkoutStep === "SchedulePickup" && (
                    <SchedulePickup />
                )}

                {checkout.checkoutStep === "ScheduleDelivery" && (
                    <ScheduleDelivery />
                )}

                {checkout.checkoutStep === "Payment" && (
                    <Payment />
                )}

                {checkout.checkoutStep === "Receipt" && (
                    <Receipt/>
                )}
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({

});

const mapStateToProps = (state) => ({
    checkout: state.checkout,
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingForm);
