import React, { useEffect, useState } from "react";
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { connect } from 'react-redux';
import BookingButton from '../../components/BookingForm/BookingButton';

function CheckoutForm({
    email,
}) {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: "https://subtle-custard-8f7aa3.netlify.app/receipt/",
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occurred.");
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
    <form id="payment-form" onSubmit={handleSubmit}>

        <LinkAuthenticationElement
            id="link-authentication-element"
            options={{
                defaultValues: {email: email}
            }}
            />
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <div className="flex grid grid-cols-3 align-items-center pt-5 align-items-center">

            {isLoading ?
                <div className="col-span-3">
                    <img src="./spinner.png" alt="loading"
                        className="object-contain w-10 m-auto animate-spin"/>
                </div>
            :
                <>
                    <div className="col-span-1">
                        <BookingButton step="ScheduleDelivery">
                            Go Back
                        </BookingButton>
                    </div>
                    <div className="col-span-2">
                        <button disabled={isLoading || !stripe || !elements} id="submit"
                            className="card-button">
                            <span id="button-text">
                                Pay now
                            </span>
                       </button>
                    </div>
                </>
            }
        </div>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
    </form>
    );
}

const mapStateToProps = (state) => ({
    email: state.checkout.email,
    checkoutClientSecret: state.checkout.clientSecret,
});

export default connect(mapStateToProps, null)(CheckoutForm);
