import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    authLogout,
    firebasePickups,
    firebaseGetCustomToken,
    firebaseSetOrderDelivery,
} from '../store/Actions';

const Admin = ({
    doAuthLogout,
    doFirebasePickups,
    doFirebaseGetCustomToken,
    doFirebaseSetOrderDelivery,
    doSetOrderDelivery,
    pickups,
    state,
    user,
}) => {
    const [ deliveries, setDeliveries ] = useState({});

    const memoizedDoFirebasePickups = useCallback(() => {
        doFirebasePickups({})
    }, [doFirebasePickups]);

    useEffect(() => {
        memoizedDoFirebasePickups()
    }, [memoizedDoFirebasePickups]);

    const memoizedDoFirebaseGetCustomToken = useCallback(() => {
        doFirebaseGetCustomToken(user.user.uid);
    }, [doFirebaseGetCustomToken, user.user.uid])

    useEffect(() => {
        memoizedDoFirebaseGetCustomToken();
    }, [memoizedDoFirebaseGetCustomToken]);

    const handleLogout = () => {
        doAuthLogout();
    }

    const handleDeliveryOnClick = (event) => {
        console.log(event.currentTarget.dataset);
        doSetOrderDelivery({
            id: event.currentTarget.dataset.id,
            delivery: true
        });
    }

    useEffect(() => {
        console.log(user);
    }, [user]);

    useEffect(() => {
        if (pickups !== null) {
            setDeliveries(pickups);
        }
    }, [pickups]);

    return (
        <div className="bg-gray-200/50 min-h-screen">
            <div className="flex bg-gray-300/50 shadow-lg max-w-2xl m-auto p-5">
                <Link to="/admin" className="card-button float-left">Home</Link>
                <Link to="/admin/settings" className="card-button float-left">Settings</Link>
                <button className="card-button float-right" onClick={handleLogout}>Logout</button>

            </div>
            <div className="flex flex-col bg-gray-300/50 shadow-lg mt-10 max-w-2xl m-auto p-5">
                <div>
                    Schedule of Deliveries and Pickups
                </div>
                {
                    Object.entries(deliveries).map((pickup, index) => {
                        console.log(pickup);
                        return (
                            <div key={index} className="float-left text-left bg-gray-100/50 p-5 m-5 grid grid-cols-3">
                                <div className="col-span-2">
                                    <p>Name: { pickup[1].firstName } { pickup[1].lastName }</p>
                                    <p>Phone: { pickup[1].phone }</p>
                                    <p>Email: { pickup[1].email }</p>
                                    <p>Delivery to: { pickup[1].deliveryLocation }</p>
                                    <p>Delivery Scheduled: </p>
                                    <p>Pickup from: { pickup[1].pickupLocation }</p>
                                    <p>Pickup Scheduled: </p>
                                    <p>Pickup Notes { pickup[1].pickupInstructions }</p>
                                    <p>Delivery Notes { pickup[1].deliveryInstructions }</p>
                                </div>
                                <div className="col-span-1">
                                    <button className="card-button"
                                        onClick={handleDeliveryOnClick}
                                        data-id={pickup[1].id}>
                                        Set Delivered
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    doAuthLogout: () => dispatch(authLogout()),
    doFirebasePickups: () => dispatch(firebasePickups()),
    doFirebaseGetCustomToken: (uuid) => dispatch(firebaseGetCustomToken(uuid)),
    doSetOrderDelivery: (data) => dispatch(firebaseSetOrderDelivery(data)),
});

const mapStateToProps = (state) => ({
    pickups: state.firestore.pickups,
    state: state.firestore,
    user: state.userSlice.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
