import React from 'react';
import BookingButton from '../../components/BookingForm/BookingButton';
import BookingInput from '../../components/BookingForm/BookingInput';
import CheckoutStepsAndRecipt from '../../components/BookingForm/CheckoutStepsAndRecipt';
import DateTimeSelect from '../../components/BookingForm/DateTimeSelect';

function ScheduleDelivery() {

    return(
        <div className="card">
            <div className="">
                <span className="text-3xl">
                    Schedule Your Delivery
                </span>
            </div>

            <CheckoutStepsAndRecipt/>

            <DateTimeSelect field="deliveryTime"/>

            <div className="text-2xl pt-10">
                <p>Special Delivery Instructions:</p>
            </div>
            <div className="flex px-10 py-5">
                <BookingInput field="deliveryInstructions" placeHolder="Special Delivery Intructions" disabled={false} type="textarea"/>
            </div>
            <div className="flex grid grid-cols-3 align-items-center pt-5 align-items-center">
                <div className="col-span-1">
                    <BookingButton step="SchedulePickup">
                        Go Back
                    </BookingButton>
                </div>
                <div className="col-span-2">
                    <BookingButton step="Payment">
                        Set Delivery Time
                    </BookingButton>
                </div>
            </div>
        </div>
    );
}

export default ScheduleDelivery;