import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from"moment";

function HalfHourSelect({
    checkout,
    selectedDay,
    selectedHalfHour,
    setSelectedHalfHour,
    filteredDates,
    field,
}) {
    const [ currentHours, setCurrentHours ] = useState(0);
    const [ filteredHalfHours, setFilteredHalfHours ] = useState([]);
    const [ dayTime, setDayTime ] = useState([]);

    const previousHours = () => {
        setCurrentHours(0);
    }

    const nextHours = () => {
        setCurrentHours(1);
    }

    const handleSetHourActive = (event) => {
        setSelectedHalfHour(event.currentTarget.dataset.id);
    }

    const dayTimeSpan = (adjuster = 0) =>
        Array(16)
            .fill()
            .map((_, index) =>
                moment({
                    hour: Math.floor(0.5 * (index + (adjuster * 16)) + 6),
                    minute: 30 * (index % 2)
                })
                .format("h:mm A"));

    // stubs out times to select in a given day
    useEffect(() =>  {
        const dayTimeAdjusted = dayTimeSpan(currentHours);
        setDayTime(dayTimeAdjusted);
        if (moment(selectedDay, "YYYY-MMM-D").diff(moment().format("YYYY-MMM-D")) === 0) {
            if (moment().hour() >= 13) setCurrentHours(1)
        }
    }, [currentHours, selectedDay]);

    // filter hours for day until current and filter hours from filtered dates
    useEffect(() => {
        if (!selectedDay) return;
        let filteredHalfHoursWorking = [];
        dayTime.map((halfHour) => {
            if (moment().add(30, 'minutes').diff(moment(`${selectedDay} ${halfHour}`, "YYYY-MMM-D h:mm A")) >= 0) {
                filteredHalfHoursWorking.push(moment(`${selectedDay} ${halfHour}`, "YYYY-MMM-D h:mm A").format("h:mm A"));
            }
            return true;
        });
        // filter hours based on times already scheduled for current day selected
        if (filteredDates !== null) {
            filteredDates.map((halfHour) => {
                if (selectedDay === moment(halfHour, "YYYY-MMM-D h:mm A").format("YYYY-MMM-D")) {
                    filteredHalfHoursWorking.push(moment(halfHour, "YYYY-MMM-D h:mm A").format("h:mm A"));
                }
                return true;
            });
        }
        // Add filters all time up to pickup so as to always schedule in the future
        if (field === "deliveryTime") {
            dayTime.map((halfHour) => {
                if (moment(checkout.pickupTime, "YYYY-MMM-D h:mm A").diff(moment(`${selectedDay} ${halfHour}`, "YYYY-MMM-D h:mm A")) >= 0) {
                    filteredHalfHoursWorking.push(moment(`${selectedDay} ${halfHour}`, "YYYY-MMM-D h:mm A").format("h:mm A"));
                }
                return true;
            });
        }
        setFilteredHalfHours(filteredHalfHoursWorking);
    }, [currentHours, selectedDay, dayTime, filteredDates, field, checkout]);


    // filter hours based on scheduled pickup
    return (
        <>

        <div className="flex grid grid-cols-12 pt-5">
            <div className="col-span-1 col-start-1 m-auto">
                <button className={`day-date-select-scroll
                    ${currentHours === 0 && ("disabled")}`}
                    disabled={!currentHours}
                    onClick={previousHours}>&lt;</button>
            </div>
            <div className="col-span-10 col-start-2">
                <div className="flex grid grid-cols-4 text-xs">
                    {dayTime.map((halfHour, index) => {
                        let disabled = false;
                        if (filteredHalfHours.includes(`${halfHour}`)) disabled = true;
                        return (
                        <button className={`time-date-select
                            ${halfHour === selectedHalfHour && ("active")}
                            ${disabled && ("disabled")}`}
                            disabled={disabled}
                            id={halfHour}
                            key={halfHour}
                            data-id={halfHour}
                            onClick={handleSetHourActive}>{halfHour}</button>
                        )
                    })}
                </div>
            </div>
            <div className="col-span-1 col-start-12 m-auto">
                <button className={`day-date-select-scroll
                    ${currentHours === 1 && ("disabled")}`}
                    disabled={currentHours}
                    onClick={nextHours}>&gt;</button>
            </div>
        </div>

        </>
    )
}

const mapStateToProps = (state) => ({
    checkout: state.checkout,
});

export default connect(mapStateToProps, null)(HalfHourSelect);
